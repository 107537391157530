<template>
  <div
    class="media-list-wrapper"
    :style="isInspected && {overflowY: 'inherit'}"
  >
    <Loader :isVisible="isLoading || replaceLoading" />
    <div class="media-list-container">
      <div
        :class="[(isInspector) ? 'media-list-inspector' : 'media-list']"
      >
        <div
          v-for="item in mediaList"
          :key="item.id"
        >
          <div
            class="item-wrapper"
            @click="inspectMediaItem(item.id)"
          >
            <drag :transferData="item">
              <div
                v-if="(item.config.transcodingPercent < maxPerCent || item.status === transcoding)
                  && item.type === 'vod'"
                class="cont"
              >
                <div
                  v-if="item.status !== error && item.status !== uploading"
                  class="back"
                >
                  <div
                    class="progress"
                    :style="{width: progressWidth(item)}"
                  >
                  </div>
                </div>
                <div
                  v-if="item.status === transcoding || item.status === error || replacementFile"
                  :class="['transcoding', item.status === error && 'failed-transcoding']"
                >
                  <div
                    v-if="(item.status === transcoding)"
                    :title="Math.floor(item.config.transcodingPercent) + '% Transcoding'"
                  >
                    <span>
                      {{ Math.floor(item.config.transcodingPercent) }} %
                    </span>
                    <span>
                      Transcoding
                    </span>
                  </div>
                  <span
                    v-if="item.status === uploading && replacementFile"
                    class="replacement-failed"
                  >
                    Replacement failed
                  </span>
                  <span v-else-if="item.status === error">
                    Transcoding failed
                  </span>
                </div>
              </div>
              <div
                v-if="item.status === packaging"
                class="transcoding packaging"
              >
                {{ packaging }}
              </div>
              <div
                v-if="(item.status === error && item.config.transcodingPercent >= maxPerCent)
                  || (item.status === error && item.type === 'audio')"
                class="replacement-failed pack"
              >
                Packaging failed
              </div>
              <div
                v-if="item.status === 'uploading' && item.type ==='audio'"
                class="replacement-failed pack"
              >
                Uploading
              </div>
              <img
                class="item-img"
                :alt="item.name"
                :src="getPosterImage(item)"
              />
              <inline-svg
                v-if="item.hasProtection && getCurrentAccount.brushfireProtectionEnabled"
                :src="iconProtection"
                class="svg-icon brush"
              >
              </inline-svg>
              <inline-svg
                v-if="item.contentType === 'Movie'"
                :title="'Movie'"
                :src="iconMovie"
                :class="['svg-icon content-icons',
                         item.hasProtection
                           && getCurrentAccount.brushfireProtectionEnabled
                           && 'hasBrush']"
              >
              </inline-svg>
              <inline-svg
                v-if="item.contentType === 'Event'"
                :title="'Event'"
                :src="iconEvent"
                :class="['svg-icon content-icons',
                         item.hasProtection
                           && getCurrentAccount.brushfireProtectionEnabled
                           && 'hasBrush']"
              >
              </inline-svg>
              <inline-svg
                v-if="item.contentType === 'Trailer'"
                :title="'Trailer'"
                :src="iconTrailer"
                :class="['svg-icon content-icons',
                         item.hasProtection
                           && getCurrentAccount.brushfireProtectionEnabled
                           && 'hasBrush']"
              >
              </inline-svg>
              <inline-svg
                v-if="item.contentType === 'Extra'"
                :title="'Extra'"
                :src="iconExtra"
                :class="['svg-icon content-icons',
                         item.hasProtection
                           && getCurrentAccount.brushfireProtectionEnabled
                           && 'hasBrush']"
              >
              </inline-svg>
              <div
                v-if="item.contentType === 'Episode'"
                :title="item.episodeNumber ? `Episode №${item.episodeNumber}` : 'Episode'"
                :class="['episode-wrapper',
                         item.episodeNumber && 'episode-number-wrapper',
                         item.hasProtection
                           && getCurrentAccount.brushfireProtectionEnabled
                           && 'hasBrush']"
              >
                <inline-svg
                  :src="iconEpisode"
                  class="svg-icon"
                >
                </inline-svg>
                <div
                  v-if="item.episodeNumber"
                  class="episode-number"
                >
                  {{ item.episodeNumber }}
                </div>
              </div>
              <div
                :title="item.name"
                class="item-title"
              >
                {{ item.name }}
              </div>
            </drag>
          </div>
        </div>
      </div>
      <div
        v-if="isInspector"
        class="new-inspector"
      >
        <Inspector
          :key="mediaItem.id"
          :mediaItem="mediaItem"
          :folderId="folderId"
          :getMediaList="getMediaList"
          :page="page"
          :mediaList="mediaList"
          :closeInspector="closeInspector"
          :openInspector="inspectMediaItem"
        />
      </div>
    </div>
    <div
      v-if="!mediaList.length && !isLoader"
      class="empty-search"
    >
      No media to display
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { Drag } from 'vue-drag-drop';
import { GET_MEDIA_ITEM_REQUEST } from '../../store/actions/media/mediaItem';
import { SET_EDIT_FORM } from '../../store/actions/common';
import posterSelector from '../../utils/poster/selector';
import CONFIG from '../../constants/config';
import iconProtection from '../../assets/icons/icon-brush-protection-media.svg';
import Preview from '../../assets/img/Preview2.png';
import iconExtra from '../../assets/icons/icon-extra.svg';
import iconEvent from '../../assets/icons/icon-event.svg';
import iconEpisode from '../../assets/icons/icon-episode.svg';
import iconMovie from '../../assets/icons/icon-movie.svg';
import iconTrailer from '../../assets/icons/icon-trailer.svg';

import Inspector from '../Inspector.vue';
import Loader from '../common/Loader.vue';
import LivePreviw from '../../assets/img/livePreviw.jpg';

export default {
  name: 'MediaList',
  components: {
    Inspector,
    Drag,
    Loader,
  },
  props: {
    folderId: {
      type: String,
      default: '',
    },
    mediaList: {
      type: Array,
      default: () => [],
    },
    isLoader: {
      type: Boolean,
      default: false,
    },
    getMediaList: {
      type: Function,
      default: () => {},
    },
    page: {
      type: Number,
      default: null,
    },
    hidePagination: {
      type: Function,
      default: () => {},
    },
    showPagination: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    const {
      upload,
      transcoding,
    } = CONFIG;
    return {
      posterSelector,
      iconProtection,
      isInspected: false,
      maxPerCent: upload.maxUploadPerCent,
      transcoding: transcoding.transcoding,
      error: transcoding.error,
      uploading: transcoding.uploading,
      isInspector: false,
      packaging: transcoding.packaging,
      Preview,
      LivePreviw,
      iconExtra,
      iconEvent,
      iconEpisode,
      iconMovie,
      iconTrailer,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.mediaItem.isLoading,
      replaceLoading: (state) => state.upload.isLoading,
      mediaItem: (state) => state.mediaItem.mediaItem,
      isEditedForm: (state) => state.common.isEditedForm,
      replaceUploading: (state) => state.mediaList.replaceUploading,
      uploadedFiles: (state) => state.upload.files,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getCurrentAccount',
      'isRead',
    ]),
    replacementFile() {
      const ids = this.mediaList.length
        && this.mediaList.find((item) => item.replacing && item.status === this.uploading);
      if (ids && ids.id
        && (
          !this.uploadedFiles.length
          || !this.uploadedFiles.find((item) => item.id === ids.id)
        )
      ) {
        return true;
      }

      return false;
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  created() {
    if (this.$route.query && this.$route.query.mediaId) {
      setTimeout(() => {
        this.inspectMediaItem(this.$route.query.mediaId);
      }, 1200);
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
    sessionStorage.removeItem('lastMedia');
  },
  updated() {
    if (!this.mediaList.length && this.$route.query && !this.$route.query.mediaId) {
      this.isInspector = false;
    }
  },
  methods: {
    progressWidth(item) {
      return item.config?.transcodingPercent >= this.maxPerCent ? `${this.maxPerCent}%` : `${item.config?.transcodingPercent}%`;
    },
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
    inspectMediaItem(id) {
      const params = {
        accountId: this.getCurrentAccountId,
        itemId: Number(id),
      };
      this.$store.dispatch(GET_MEDIA_ITEM_REQUEST, params)
        .then(() => {
          this.isInspector = true;
          if ((this.$route.query && !this.$route.query.mediaId)
            || +id !== +this.$route.query.mediaId) {
            this.$router.push(`?mediaId=${id}`);
          }
        })
        .catch(() => {
          this.$toasted.global.error({
            message: CONFIG.errorMessages.noVideoMessage,
          });
        });
    },
    closeInspector() {
      if (this.isEditedForm) {
        // eslint-disable-next-line no-alert
        if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
          this.$router.push(this.$route.path);
          return;
        }
        this.isInspector = false;
      }
      this.isInspector = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
      this.$router.push('');
    },
    getPosterImage(item) {
      if (item.type === 'vod') {
        if (item.status === 'transcoding' || item.status === CONFIG.transcoding.error) {
          return Preview;
        }
        return item.posterImage ? `${item.posterImage}?height=360` : Preview;
      }

      if (item.type === 'audio' && item.status === 'error') {
        return Preview;
      }

      return item.posterImage ? `${item.posterImage}?height=360` : Preview;
    },
  },
};
</script>
<style scoped>
  .media-list-wrapper {
    height: calc(100vh - 225px);
    overflow-y: auto;
    position: relative;
  }

  .media-list-wrapper::-webkit-scrollbar-track {
    background-color: #222;
  }

  .media-list-wrapper::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  .media-list-wrapper::-webkit-scrollbar-thumb {
    background-color: #4c5057;
    border-radius: 5px;
  }

  .media-list {
    display: grid;
    grid-gap: 9px 20px;
    grid-template-columns: repeat(auto-fill, minmax(294px, 1fr));
    grid-template-rows: repeat(auto-fill, 185px);
    width: 100%;
  }

  .media-list-inspector {
    display: grid;
    grid-gap: 10px 20px;
    grid-template-columns: repeat(auto-fill, minmax(216px, 1fr));
    grid-template-rows: repeat(auto-fill, 141px);
    height: calc(100vh - 230px);
    overflow-y: auto;
    width: calc(100vw - 988px);
  }

  .item-wrapper {
    background-color: #000;
    cursor: pointer;
    display: grid;
    position: relative;
    height: 185px;
    width: 294px;
  }

  .media-list-inspector .item-wrapper {
    max-height: 141px;
    max-width: 216px;
  }

  .item-img {
    display: block;
    margin: 0 auto;
    max-height: 165px;
    max-width: 294px;
    width: 100%;
  }

  .item-title {
    background-color: #222;
    bottom: 0;
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 16px;
    -webkit-line-clamp: 1;
    line-height: 19px;
    overflow: hidden;
    padding-top: 5px;
    position: absolute;
    text-align: left;
    text-overflow: ellipsis;
    width: 100%;
  }

  .media-list-inspector .item-title {
    max-width: 216px;
  }

  .media-list-inspector .item-img {
    max-height: 121px;
    max-width: 216px;
  }

  .empty-search {
    padding-top: 40px;
    text-align: center;
  }

  .progress {
    animation: move 4s linear infinite;
    background: repeating-linear-gradient(-45deg,
    #2591cf,
    #2591cf 6px,
    #25aae3 6px,
    #25aae3 12px);
    background-size: 50px 50px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }

  .cont {
    height: 6px;
    position: absolute;
    width: 100%;
  }

  .back {
    background-color: #43434d;
    height: 100%;
  }

  .transcoding {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 14px;
    -webkit-line-clamp: 1;
    overflow: hidden;
    padding: 10px 0 0 10px;
    text-align: left;
    text-overflow: ellipsis;
  }

  .failed-transcoding {
    padding-top: 16px;
  }

  .none {
    display: none;
  }

  .packaging {
    position: absolute;
    text-transform: capitalize;
  }

  .episode-wrapper {
    position: absolute;
    z-index: 2;
    top: 8px;
    right: 8px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
  }

  .episode-number {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    padding-left: 3px;
  }

  .episode-number-wrapper {
    width: 43px;
    border-radius: 10px;
  }

  .content-icons {
    width: 18px;
    height: 18px;
    position: absolute;
    z-index: 2;
    top: 8px;
    right: 8px;
  }

  .hasBrush {
    right: 35px;
  }

  @keyframes move {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 50px 50px;
    }
  }

  .media-list-container {
    display: flex;
  }

  .new-inspector {
    height: calc(100vh - 250px);
    margin: 0 10px;
    width: 570px;
  }

  .replacement-failed {
    position: absolute;
    top: 16px;
  }

  .pack {
    padding-left: 10px;
    font-size: 14px;
  }

  .brush {
    position: absolute;
    z-index: 2;
    top: 8px;
    right: 8px;
    width: 18px;
    height: 18px;
  }

  @media all and (max-width: 1200px) {
    .media-list-inspector {
      width: calc(100vw - 640px);
    }
  }

  @media all and (max-width: 1070px) {
    .media-list-inspector {
      min-width: 221px
    }

    .transcoding {
      padding: 5px 0 0 5px;
    }
  }
</style>
